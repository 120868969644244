


export const actualLocation = (setGeolocation) => {
        // let locationObj = {}
        // console.log(navigator.geolocation, 'GEOLOCATION', navigator);
        if(!navigator.geolocation?.getCurrentPosition)setGeolocation({text:"Not available"});
        (() => navigator.geolocation 
            && navigator.geolocation.getCurrentPosition(r => {
                // console.log({r}, '______________________________________________________')
                    if(!r.coords)setGeolocation({text: "Geolocation not available"});
                    const {latitude, longitude} = r.coords;
                    const loc = {...r.coords, text:`${latitude}, ${longitude}`};
                    // console.log(loc);
                    (() => setGeolocation({...loc}))();
        }))()
};
export const getActualLocation = (logObj) => {
        
        (() => navigator.geolocation 
            && navigator.geolocation.getCurrentPosition(r => {
                // console.log({r})
                    const {latitude, longitude} = r.coords;
                    const loc = {...r.coords, text:`${latitude}, ${longitude}`};
                //     (() => setGeolocation({...loc}))();
                    logObj.location = loc;
        }))()
        // console.log({logObj})
        return logObj;
};










