import React from "react";
import {Form} from "react-bootstrap";


const TextField = ({name, props}) => {
    // console.log('Textfield', {name, props});
    const {
        variables,
        setVariables,
        handleChange,
        fieldSettings, 
        fieldErrors, 
        } = props
        const {label, required, autoFocus, toolTip, placeholder, type, visible, disabled} = fieldSettings[name];
        const value = variables[name] || "";
        // console.log({name,value});
    const maxLength = fieldSettings[name]?.validation?.maxLength || 0;
    // console.log({name, maxLength}, '#############');
    const handleChangeLocal = e => {
        const len = e.target?.value?.length || 0;
        // console.log({})
        if(!maxLength || len <= maxLength)handleChange(e);
    }
    const handleBlur = e => {
        const origin = e.target.value;
        const trimmed = e.target.value.trim();
        console.log('in handle blur', {origin, trimmed}, origin.length, trimmed.length, variables[name])
        // console.log("in handleBlur", e.target, e.target.value.length);
        // console.log(e.target.value.trim())
        // console.log({...variables, [variables[name]]: trimmed})
        setVariables(vars => {return{...vars, [name]: trimmed}});
    }
    
    if (visible === false) return null;

    console.log({variables});
    return(
        <Form.Group controlId={name} >
        {/* <Form.Group size="lg" controlId={name} > */}
            {/* <Form.Label style={{color:"#1a4a70"}} className ="pt-1 px-1" >{label}{required? ' * ':' '} */}
            <Form.Label  className ="pt-1 px-1" >{label}{required? ' * ':' '}
            { fieldErrors[name]?<small className="text-danger" > - {fieldErrors[name]}</small>:null}
            </Form.Label>
            <Form.Control 
                style = {fieldErrors[name] ? {border:"2px solid red", backgroundColor:"rgb(255,235,235)"}:{}}
                name={name} 
                disabled={disabled}
                type={type} 
                key={name} 
                autoFocus={autoFocus}
                value={value}
                required = {required}
                placeholder={placeholder}
                onChange={e => handleChangeLocal(e)} 
                onBlur={e => handleBlur(e)}
                // onBlur={e => validateField(e)}
            />
            {toolTip?<p><small className="text-black p-1" >{toolTip}</small></p>:null}
        </Form.Group>
    )
}
export default TextField;
