import React, {useState} from "react";
import {Form} from "react-bootstrap";
import ValueArea from "./ValueArea";
import { translate } from "../../components/Language/Translate";
import FieldError from "../Fields/FieldError";

const TextFieldWithValues = ({name, props, array}) => {
    const [input, setInput] = useState('');
    const {variables,setVariables} = props

    return(
        <Form.Group size="lg" controlId={name} >
            <Form.Label  className ="pt-1 px-1" >
                {translate("Species")}{` * `}<FieldError name="speciesId" props={props}></FieldError>
            </Form.Label>
            <Form.Control 
                name={name} 
                type="text" 
                key={name} 
                value={input}
                placeholder={translate('Write some chars and then select from list')}
                onChange={e => setInput(e.target.value)} 
            />
            {input.length > 1 && <ValueArea str={input} arr={array} setVariables={setVariables} variables={variables}/>}
        </Form.Group>
    )
}
export default TextFieldWithValues;
