import React from "react";
import { BlackWhite } from "../../components/styledComponents/colors";

const ValueArea = ({str, arr, setVariables}) => {

    const handleClick = e => setVariables(variables => {return{...variables, speciesId: +e.target.parentElement.id}});

    const newArr = arr.filter(i => {
        const fullname = `${i.deDE} / ${i.enUK}`.toLowerCase();
        return fullname.includes(str.toLowerCase());
    });

    return (
        <div className="mt-3">
            <ul >
                {newArr.map(i => 
                    <li key = {i.id} id = {i.id}
                        onClick = {e => handleClick(e)}>
                        <BlackWhite>{`${i.deDE} / ${i.enUK}`}</BlackWhite>
                    </li>)}
            </ul>
        </div>
    )
};
export default ValueArea;