import React, { useState, useEffect } from "react";
import { simpleRequest } from "../../../../newForms2/simpleRequest";
import { translate } from "../../../Language/Translate";
import ObservationNew from "../../../../newForms2/observations/ObservationNew";
import ObservationListCruise from "./ObservationListCruise";
import SpeciesGroupsMain from "../../../../newForms2/observations/speciesGroups/SpeciesGroupsMain";
import Spinner from "../../../Spinner";
import { getNewDateObject } from "../../../../helpers/Date";

const ObservationMain = () => {
  const [mainLoading, setMainLoading] = useState(true);
  const [speciesGroups, setSpeciesGroups] = useState([]);
  const [species, setSpecies] = useState([]);
  const [speciesObservations, setSpeciesObservations] = useState([]);
  const [openForm, setOpenForm] = useState("list"); // list, addObservation, addSpeciesGroup, updateSpeciesGroup

  useEffect(() => {
    const fetch = async () => {
      const sg =
        (await simpleRequest({ url: "speciesgroups" })).data?.speciesGroups ||
        [];
      const s = (await simpleRequest({ url: "species" })).data?.species || [];
      const so =
        (await simpleRequest({ url: "speciesobservations" })).data
          ?.speciesObservations || [];
      (() => setSpeciesGroups(sg))();
      (() => setSpecies(s))();
      (() => setSpeciesObservations(so))();
      (() => setMainLoading(false))();
    };
    if (mainLoading) fetch();
  }, [mainLoading]);

  const newArr = []
  speciesGroups.forEach((sg) => {
    // console.log(sg.deDE)
    species.filter((s) => s.speciesGroupId === sg.id)
    .forEach(s => {
        console.log(s.deDE)
        newArr.push({group: sg.deDE, species: s.deDE, en: s.enUK})
    });
    
  });
//   console.log({ newArr });

  if (mainLoading) return <Spinner />;

  const mainProps = {
    mainLoading,
    setMainLoading,
    openForm,
    setOpenForm,
    speciesGroups,
    setSpeciesGroups,
    species,
    setSpecies,
    speciesObservations,
    setSpeciesObservations,
  };
  const initialValues = {
    dateTime: getNewDateObject(new Date()),
    geolocation: "",
    destinationId: 0,
    albumId: 0,
    speciesId: 0,
    description: "",
  };
  // console.log({mainProps});
  return (
    <div>
      {openForm === "list" && (
        <button
          className="btn btn-outline-dark"
          onClick={() => setOpenForm("addObservation")}
        >
          {translate("Add new Observation")}
        </button>
      )}
      {openForm === "list" && (
        <button
          className="btn btn-outline-dark ml-1"
          onClick={() => setOpenForm("speciesGroupList")}
        >
          {translate("Groups")}
        </button>
      )}
      {openForm === "addObservation" && (
        <ObservationNew mainProps={mainProps} initialValues={initialValues} />
      )}
      {openForm === "list" && <ObservationListCruise mainProps={mainProps} />}
      {openForm.includes("peciesGroup") && (
        <SpeciesGroupsMain mainProps={mainProps} />
      )}
    </div>
  );
};

export default ObservationMain;
