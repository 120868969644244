function sortArrayByIndex (getArr,intInd) {   
    let modified=true;
    while (modified){
        modified=false;
        for(let j=0;j<getArr.length-1;j++) {
            let arrClb=[]
            
            if (getArr[j][intInd] > getArr[(j+1)][intInd]) {
                arrClb=getArr[j];
                getArr[j]=getArr[j+1];
                getArr[j+1]=arrClb;
                modified=true;
            }
        }
    }
    
    return getArr;
 }
function sortArrayByObjectField (getArr,fieldname, dataType=null) {   
    // console.log({getArr, fieldname})
    const newArr = [...getArr]
    // console.log({newArr, dataType})
    // let change=false;
    let modified=true;
    while (modified){
        modified=false;
        for(let j=0;j<newArr.length-1;j++) {
            let change = false;
            let arrClb={}
            if (dataType === null){
                if (newArr[j][fieldname] > newArr[(j+1)][fieldname])change=true;
            }else if(dataType === "float"){
                if (parseFloat(newArr[j][fieldname]) > parseFloat(newArr[(j+1)][fieldname]))change=true;
            };
            if (change){
                arrClb=newArr[j];
                newArr[j]=newArr[j+1];
                newArr[j+1]=arrClb;
                modified=true;
            };
        };
    };
    
    return [...newArr];
 }
;
export {sortArrayByIndex, sortArrayByObjectField};
