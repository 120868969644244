import React, { useState, useEffect } from "react";
import {Form} from 'react-bootstrap';
import { simpleRequest } from "../../simpleRequest";
import { useDispatch, useSelector } from "react-redux";
import { fetchDestinationsFromAPI } from "../../../actions/destinations";
import Spinner from "../../../components/Spinner";
import { translate } from "../../../components/Language/Translate";
import { actualLocation } from "../../../helpers/location";
import TextField from "../../Fields/TextField";
import TextFieldSelect from "./TextFieldSelect";
import { getGeolocationArrayFromAlbums, getGeolocationArrayFromDestinations, getTextGeo } from "../../../components/species/helpers";

const Geolocations = ({props}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [geoloc, setGeoloc] = useState({});
    const [choice, setChoice] = useState("selectPort");
    const {setVariables, variables} = props;
    const [array, setArray] = useState([]);
    const [albums, setAlbums] = useState([]);
    const destinations = useSelector(st => st.destinations);
    const dispatch = useDispatch();
    const textGeo = getTextGeo(variables, albums, destinations);
    const label = choice === "selectPort" ? "Port / Destination" : "Album";

    useEffect(() => {
        const fetch = async() => {
            if(!destinations.length)dispatch(fetchDestinationsFromAPI());
            const albs = (await simpleRequest({url:"albums"})).data?.albums || []
            setAlbums([...albs]);
            setArray(getGeolocationArrayFromDestinations(destinations));
            setIsLoading(false);
        }
        if(isLoading)fetch();
    }, [isLoading, dispatch, destinations])


    if(isLoading)return <Spinner/>

    const handleGetGeolocation = e => {
        e.preventDefault();
        actualLocation(setGeoloc);
        setVariables(variables => {return{...variables, geolocation: geoloc.text || ''}});
    }
    const handleSetSelect = e => {
        setVariables({...variables, geolocation:""});
        const {id} = e.target;
        const arr = id === "selectPort" ? getGeolocationArrayFromDestinations(destinations)
                : id === "selectAlbum" ? getGeolocationArrayFromAlbums(albums)
                : [];
        setChoice(id);
        setArray([...arr]);
    }
    const GeoInput = () => {
        const [isLoading, setIsLoading] = useState(true)
        useEffect(() => {
            actualLocation(setGeoloc);
            setIsLoading(false)
        }, [isLoading, setIsLoading])
        return(
            <div className = "pb-3 mb-3" style = {{borderBottom:"1px solid green"}}>
                <TextField name = "geolocation" props={props}/>
                <button className = "btn btn-outline-dark" onClick = {e => handleGetGeolocation(e)}>
                    {translate("Actual Geolocation")}
                </button>

            </div>
        )
    }
    const SelectInput = () => {
        return(
            <>
            <Form.Group>
                <Form.Label>{translate(label)}</Form.Label>
                <TextFieldSelect name="geolocation" props={props} array = {array}/>
            </Form.Group>
            </>
        );
    }
    const Result = () => {
        const handleLocalClick = () => {
            setVariables({...variables, albumId:0, destinationId:0, geolocation:""})
        }
        return(
            <p>
                <button className="btn btn-outline-danger mr-2" onClick = {handleLocalClick}>X</button>
                <b>{`Geolocation: ${textGeo}`}</b>
            </p>
        )
    };
        
    // console.log({geoloc, array, choice, albums});
    const FormInput = () => {
        return(
            <>
                <Form.Group>
                    <Form.Label>Geolocation</Form.Label>
                    <Form.Check
                        id="selectPort"
                        type="radio"
                        label={translate("Get from destination / port")}
                        // value="selectInput"
                        checked= {choice === "selectPort"}
                        onChange={e => handleSetSelect(e)}
                    >
                    </Form.Check>
                    <Form.Check
                        id="selectAlbum"
                        type="radio"
                        label={translate("Get from album")}
                        // value="selectInput"
                        checked= {choice === "selectAlbum"}
                        onChange={e => handleSetSelect(e)}
                    >
                    </Form.Check>
                    <Form.Check
                        type="radio"
                        label={translate('Insert geolocation / actual geolocation')}
                        // value="geoInput"
                        checked= {choice === "geoInput"}
                        onChange={()=>setChoice("geoInput")}
                        placeholder={`Format: 54.232, 8.777`}
                    >
                    </Form.Check>
                </Form.Group>
                {(choice === "selectAlbum" || choice === "selectPort") && <SelectInput/>}
                {choice === "geoInput" && <GeoInput/>}
            </>
        )
    };
    const {geolocation, albumId, destinationId} = variables;
    return (
        <div >
            {(!!geolocation || !!albumId || !!destinationId) && <Result />}
            {!(geolocation || albumId || destinationId) && <FormInput />}
        </div>
    );
};
export default Geolocations;